export const BLOCKED_ADDRESSES: string[] = [
  // SDN OFAC addresses
  '0x7Db418b5D567A4e0E8c59Ad71BE1FcE48f3E6107',
  '0x72a5843cc08275C8171E582972Aa4fDa8C397B2A',
  '0x7F19720A857F834887FC9A7bC0a0fBe7Fc7f8102',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x1da5821544e25c636c1417Ba96Ade4Cf6D2f9B5A',
  '0x9F4cda013E354b8fC285BF4b9A60460cEe7f7Ea9',
  '0x19Aa5Fe80D33a56D56c78e82eA5E50E5d80b4Dff',
  '0x2f389cE8bD8ff92De3402FFCe4691d17fC4f6535',
  '0xe7aa314c77F4233C18C6CC84384A9247c0cf367B',
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
  '0xC8a65Fadf0e0dDAf421F28FEAb69Bf6E2E589963',
  '0x308eD4B7b49797e1A98D3818bFF6fe5385410370',
  // END SDN OFAC addresses
  // kyberswap exploit
  '0xa423C7Be031E988B25fb7Ec39b7906582f6858c6',
  '0xc0f9c4B3d29a522140F3002972a7D07d7566E296',
  '0x50275E0B7261559cE1644014d4b78D4AA63BE836',
  '0xC9B826BAD20872EB29f9b1D8af4BefE8460b50c6',
  '0x4Ea83653eceA38B51730c14776698E19f5ca6e65',
  '0xa423C7Be031E988B25fb7Ec39b7906582f6858c6',
  '0xc0f9c4B3d29a522140F3002972a7D07d7566E296',
  '0xd3a7e3c5602f8a66b58dc17ce33f739efac33da2',
  '0x98d69d3eA5F7E03098400A5BEdFBE49F2B0B88D3',
  '0x5E42DD64266C3852cad3d294f71b171459Cf0a48',
  '0x84E66f86C28502C0fC8613e1D9CbBEd806F7Adb4',
  // END kyberswap exploit
];
